<!--주문 관리 페이지 메인-->
<template>
	<div>
		<!--검색 조건 카드 시작-->
		<searchCard>
			<div class="flex">
				<div class="w-1/7">
					<h6>날짜 형식</h6>
					<vSelect v-model="opt.dateType" :clearable="false" :options="dateOpts" />
				</div>
				<div class="w-2/7 pl-4">
					<h6 class="block w-1/1">&nbsp;</h6>
					<div class="flex">
						<datePicker class="w-3/7" model="opt.startDate" />
						<span class="p-2 lh-6">~</span>
						<datePicker class="w-3/7" model="opt.endDate" />
					</div>
				</div>
				<div class="w-1/7">
					<h6>주문 상태</h6>
					<vSelect v-model="opt.status" :clearable="false" :options="statusOpts" />
				</div>
				<div class="w-1/7 ml-4">
					<h6>키워드 검색</h6>
					<v-select v-model="opt.searchKeyName" :clearable="false" :options="searchKeyOpts" />
				</div>
				<div class="w-1/3 mx-2">
					<h6>&nbsp;</h6>
					<div class="flex">
						<b-form-input
							class="w-9/12"
							v-model.trim="opt.searchKeyword"
							name="keyword"
							placeholder="검색어"
							@keyup.enter="search"
						/>
						<b-button class="px-10 ml-2" @click="search">검색</b-button>
					</div>
				</div>
			</div>
		</searchCard>

		<!--검색 결과 리스트 시작-->
		<b-card>
			<b-button class="float-right ml-4" @click="insertTrackingNumber" variant="gray">운송장 일괄등록</b-button>
			<b-button class="float-right ml-4" @click="excelExport">엑셀 다운로드 요청 </b-button>
			<b-button class="float-right" @click="downloadOrderForm" variant="info">주문 발주서 다운로드</b-button>

			<tb class="mt-14" :inf="inf" :isStriped="false" :pr="ths" :res="res" checkBoxKey="hottOrderNum">
				<template #product="{ h, v, i }" :class="'text-primary'">
					<table class="table b-table orderListInnerTable mb-0">
						<tbody>
							<tr v-for="item in v.orderProductInfos">
								<td :class="`w-${inf[inf.length - 2].size}`" class="text-left">
									{{ item.productName }} ({{ comma(item.productCount) }})
								</td>
								<td :class="`w-${inf[inf.length - 1].size}`">{{ item.orderStatus }}</td>
							</tr>
						</tbody>
					</table>
				</template>
			</tb>
		</b-card>
		<insertTrackingNumberPopup :p="ths" type="order" />
	</div>
</template>

<script>
import insertTrackingNumberPopup from 'comp/local/insertTrackingNumberPopup'

const now = new Date(),
	dateOpts = [
		{ label: '주문일자', value: 'order' },
		{ label: '결제일자', value: 'pay' },
	],
	searchKeyOpts = [
		{ label: '주문번호', value: 'hottOrderNum' },
		{ label: '장바구니 주문번호', value: 'cartOrderNum' },
		{ label: '주문자명', value: 'fromName' },
		{ label: '주문자 연락처', value: 'fromPhone' },
		{ label: '수령자명', value: 'toName' },
		{ label: '수령자 연락처', value: 'toPhone' },
		{ label: '배송지', value: 'toAddress' },
		{ label: '딜 번호', value: 'dealNum' },
		{ label: '딜명', value: 'dealName' },
	],
	defOpt = {
		dateType: dateOpts[0],
		startDate: getMonth(now, -1),
		endDate: now,

		status: { label: '전체', value: '' },

		searchKeyName: searchKeyOpts[0],
		searchKeyword: '',
	}

export default {
	name: 'keep',
	components: { insertTrackingNumberPopup },
	data() {
		return {
			dateOpts,
			searchKeyOpts,
			statusOpts: [],

			opt: { ...defOpt },
			lastOpt: {},
			res: {},

			inf: [
				{ title: 'No.', model: 'idx', size: 3 },
				{
					title: '주문번호',
					model: 'button',
					size: 10,
					text: item => item.hottOrderNum,
					bVariant: 'no',
					class: 'text-primary',
					func: item => page('orderDetail', { orderNum: item.hottOrderNum }),
				},
				{ title: '주문일자', key: 'orderDate', size: 6 },
				{ title: '결제일자', key: 'payDate', size: 6 },
				{ title: '딜명', key: 'dealName', size: 16, notCenter: 1 },
				{ title: '딜 번호', model: 'urlText', url: 'dealLink', text: 'dealNum', size: 5 },

				{ title: '수령자', key: 'toName', size: 4 },
				{ title: '연락처', key: 'toPhone', size: 7 },
				{ title: '송장번호', key: 'invoice', size: 12 },
				{ title: '배송지', key: 'address', size: 16, notCenter: 1 },
				{ title: '결제금액', key: 'price', size: 5, tdClass: 'text-right pr-2' },
				{ title: '결제방법', key: 'payMethod', size: 6 },
				{ title: '상품명(수량)', key: 'product', model: 'product', size: 20, colspan: 2 },
				{ title: '주문상태', size: 5 },
			],
		}
	},
	methods: {
		search(operation = 'LIKE') {
			//검색 시작
			if (typeof operation != 'string') operation = 'LIKE'
			const opt = this.opt,
				startDate = opt.startDate.get_time('d'),
				endDate = opt.endDate.get_time('d'),
				orderStatus = getDropdownValue(opt.status),
				searchKey = getDropdownValue(opt.searchKeyName),
				searchVal = opt.searchKeyword

			let data = {
					dateType: getDropdownValue(opt.dateType),
					startDate,
					endDate,
					paging: getPaging(),
				},
				//선택 조건에 없는 필수값들 입력
				e = []

			if (startDate > endDate) e.push('시작일이 종료일보다 큽니다.')

			if (orderStatus) data.orderStatus = orderStatus

			//유효성 검사 및 서버에 보낼 데이터 채우기
			if (searchVal)
				data.searchKey = getSearchKey(searchKey ? searchKey : searchKeyOpts, searchVal, 'LIKE', 'and')
			if (e.length)
				//유효성 검사에서 오류가 있을 경우 알럿
				alert.w(e)
			else {
				this.tb.clearChecked()
				//오류가 없으면 검색 조건 설정하고 1페이지로 설정
				this.lastOpt = data
				//this.selectedItem = {}
				return this.changePage()
			}
		},
		changePage(page = 1, ex = false) {
			//마지막 검색 조건을 기준으로 페이지 변경
			this.lastOpt.paging.pageNo = page - 1
			return postApi('orderSelect/orderManagementList', addExcelData(this, ex)).then(res => {
				if (!ex) {
					res.list = res.list.map(v => {
						v.dealLink = `${dealHost}/${v.dealNum}`

						const p = v.orderProductInfos[0]
						v.invoice = `${p.deliveryCompany ?? ''} ${p.invoiceNum ?? ''}`
						v.address = `${v.toAddress ?? ''} ${v.toAddress2 ?? ''}`
						v.price = numFormat(v.price)

						v.productOpt = { colspan: 2 }
						return v
					})

					this.res = res
				} else alert.excel()
			})
		},
		excelExport() {
			const opt = this.lastOpt,
				ex = new excel(),
				dateType = this.dateOpts.filter(v => v.value == opt.dateType)[0].label

			ex.date(opt.startDate, opt.endDate, dateType)
			ex.dropdown(opt.orderStatus, this.statusOpts, '주문 상태')
			ex.search(opt.searchKey, searchKeyOpts)
			ex.go(this)
		},
		downloadOrderForm() {
			if (this.$acl.get[0] != 'ROLE_DELIVERY' && this.$acl.get[0] != 'ROLE_ADMIN')
				return alert.w('다운로드 권한이 없습니다.')
			const ex = new excel('주문 발주서 다운로드')
			postApi('api/backoffice/orderHandle/downloadOrderForm', { excelInfo: ex.get() }).then(() => alert.excel())
		},
		insertTrackingNumber() {
			this.insertTrackingNumberPopup.open()
		},
	},
	created() {
		getConfig('delivery_status').then(res => {
			this.statusOpts = res.delivery_status
			this.search()
		})
	},
}
</script>

<style>
.orderListInnerTable tr:first-child td {
	border-top: none;
}
</style>
