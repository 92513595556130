<template>
	<basePopup :cancelFunc="cancel" :okFunc="save" title="운송장 일괄등록" size="md">
		<div class="flex">
			<b-form-file
				v-model="input.file"
				placeholder="파일을 선택해주세요."
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				browse-text="파일 찾기"
			/>
		</div>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
import { uploadFile } from 'libs/axios'

export default {
	props: { type: { default: 'order' }, cName: { default: 'insertTrackingNumberPopup' } },
	//order, sponsor
	components: { basePopup },
	data() {
		return {
			isShow: false,
			input: {
				file: null,
			},
		}
	},
	methods: {
		open(item) {
			this.input.file = null
			this.isShow = true
		},
		save() {
			if (!this.input.file) return alert.w('파일을 업로드해 주세요.')

			uploadFile(
				this.input.file,
				'trackingNumber',
				`api/backoffice/${this.type}Handle/insertTrackingNumber`
			).then(() => {
				this.isShow = false
				alert.s('운송장 업로드가 완료되었습니다.')
				reload(this, true)
			})
		},
		cancel() {
			if (this.input.file)
				confirm('등록을 완료하지 않은 파일이 있습니다.\r\n등록을 취소하시겠습니까?', () => {
					this.isShow = false
				})
			else this.isShow = false
		},
	},
}
</script>
